<template>
  <section class="CreateAndUpdateProvider_root">
    <AppSection title="Add Provider">
      <div slot="tollbar">
        <RoundButton
          class="CreateAndUpdateProvider_backBtn"
          withIcon
          iconType="arrowLeft"
          text="back to dashboard"
          @click="goBack"
        />
      </div>
      <div class="CreateAndUpdateProvider_wrapper" slot="content">
        <v-form class="CreateAndUpdateProvider_Form" ref="form">
          <Select
            class="CreateAndUpdateProvider_select"
            :items="providersList"
            @change="onSelectChange(arguments[0], 'providerName')"
            name="providerName"
            label="Provider"
            :value="providerName"
            componentName="VAutocomplete"
            :rules="[...inputRules.required]"
            :hide-details="false"
            :disabled="!!provider"
          />
          <TextFieldInput
            requireStar
            v-model="name"
            name="name"
            placeholder="Name"
            :rules="[...inputRules.required]"
            label="Name"
          />
          <CurrenciesList 
            ref="CurrenciesList"
            v-if="!provider && availableCurrency"
            :availableCurrency="availableCurrency"
            :selectedCurrency="selectedCurrency"
            :required="true"
            :providerType="provider"
            @changeSelectedCurrency="changeSelectedCurrency"
          />
          <div class="CreateAndUpdateProvider_FormBody" v-if="!provider && dynamicFields.length">
            <TextFieldInput v-for="field in dynamicFields" :key="field.name"
              :value="dynamicFieldsForm[field.name]"
              :name="field.name"
              placeholder=""
              :rules="field.is_required ? [...inputRules.required] : []"
              :requireStar="field.is_required"
              :label="field.description || 'Label Name'"
              @input="handleDynamicFieldInput(arguments[0], field.name)"
            />
          </div>
          <div class="flex gap-10 md:gap-20">
            <Button 
              class="btn-default-large btn-full-width"
              text="cancel"
              size="large"
              @click="goBack"
              hoverIconColor="#2d4d70"
              fillIconColor="#2af3f3"
            >
              <template v-slot:icon="{ fillIconColor }">
                <iconArrowLeft :fillColor="fillIconColor" />
              </template>
            </Button>
            <Button
              class="btn-full-width btn-default-large btn-default-green"
              text="SAVE"
              size="large"
              @click="handleSubmitActivateProvider"
            >
              <template #icon>
                <iconVerified :height="12" :width="12" />
              </template>
            </Button>
            <!-- <Button
              class="btn-default-large btn-full-width btn-default-red ml-[7px]"
              text="delete"
              red
              size="large"
              @click="handleDeleteProvider"
            >
              <template #icon>
                <iconDelete fillColor="#ff556d" />
              </template>
            </Button> -->
          </div>
        </v-form>
      </div>
    </AppSection>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RoundButton from '@/components/_common/FormElements/RoundButton/RoundButton';
import AppSection from '@/components/_common/AppSection/AppSection';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import Select from '@/components/_common/FormElements/Select/Select';
import Button from '@/components/_common/FormElements/Button/Button';
import inputRules from '@/utils/inputRules';
import { validateForm } from '@/mixins/validateForm';
import CurrenciesList from './CurrenciesList/CurrenciesList';
import { arrowLeft as iconArrowLeft } from '@/assets/icons/arrows/';
import { iconDelete, iconVerified } from '@/assets/icons/common/';
import ProviderInfo from './ProviderInfo/ProviderInfo';
import { showAppNotification } from '@/utils/appNotification';

export default {
  components: {
    AppSection,
    Button,
    Select,
    RoundButton,
    TextFieldInput,
    CurrenciesList,
    iconDelete,
    iconArrowLeft,
    iconVerified,
    ProviderInfo,
  },
  mixins: [validateForm],
  data() {
    return {
      inputRules,
      providerName: 'BILLLINE',
      name: '',
      dynamicFields: [],
      dynamicFieldsForm: {},
      availableCurrency: [],
      selectedCurrency: [],
    }
  },
  computed: {
    ...mapState('providers', ['supportedProviderIntegrations', 'activateProviderForm', 'userProviders']),
    providersList() {
      return ['BILLLINE', 'CRYPTO_NODE']
    },
    provider() {
      let provider = null;
      for (let index in this.userProviders) {
        if (this.userProviders[index].id == this.$route.params.providerId) {
          provider = this.userProviders[index];
        }
      }
      return provider;
    },
  },
  methods: {
    ...mapActions('providers', [
      'apiGetSupportedProviderIntegrations',
      'updateActivateProviderForm',
      'apiGetFieldsArrayBySelectedProvider',
      'apiActivateProvider',
      'apiGetUserProviders',
      'apiUpdateProvider',
    ]),
    updateDynamicFieldsBySelectedProvider() {
      this.dynamicFields = []
      this.dynamicFieldsForm = {}
      this.apiGetFieldsArrayBySelectedProvider( this.providerName ).then( data => {
        this.dynamicFields = data.fields;
        this.availableCurrency = data.currencies.filter( item=>item!=="RUB" )
        data.fields.forEach( field => {
          this.dynamicFieldsForm[field.name] = ''
        })
      })
    },
    goBack() {
      this.$router.push({ name: 'providers.dashboard' })
    },
    handleDynamicFieldInput(value, field) {
      this.dynamicFieldsForm[field] = value
    },
    onSelectChange({ value }) {
      this.providerName = value
      this.updateDynamicFieldsBySelectedProvider()
    },
    handleSubmitActivateProvider() {
      let isCurrecyValid = this.$refs.CurrenciesList?.isValid()
      this.validateForm().then(() => {
        if (this.provider) {
          const request = {
            name: this.name,
            payment_account_id: this.provider.id,
            // provider: this.providerName,
            // currencies: this.selectedCurrency,
            // account_setup_fields: this.dynamicFieldsForm,
          };
          this.apiUpdateProvider(request).then(() => {
            showAppNotification("success", "Provider updated successfully");
          });
        } else {
          if (!isCurrecyValid) return false;
          const request = {
            provider: this.providerName,
            currencies: this.selectedCurrency,
            name: this.name,
            account_setup_fields: this.dynamicFieldsForm,
          };
          this.apiActivateProvider(request).then(() => {
            showAppNotification("success", "Provider successfully created");
          });
        }
      });
    },
    handleDeleteProvider() {
      console.log('delete');
    },
    changeSelectedCurrency(selectedCurrency) {
      this.selectedCurrency = selectedCurrency
    },
    setProviderFields() {
      if (!this.provider) return
      this.providerName = this.provider.provider
      this.name = this.provider.name
      this.selectedCurrency = this.provider.currencies
      // this.operationType = this.router.operation_type
      // this.payCurrency = this.router.pay_currency
      // this.paymentMethod = this.router.payment_method
      // this.selectedCurrency = this.router.currencies
      // this.fieldsRouteFee.forEach(field=>{
      //   field.value = `${this.router.fee[field.name]}`
      // })
      // this.fieldsRouteLimits.forEach(field=>{
      //   field.value = `${this.router.limits[field.name]}`
      // })
    }
  },
  async mounted() {
    await this.apiGetUserProviders()
    this.updateDynamicFieldsBySelectedProvider()
    this.setProviderFields()
  },
};
</script>

<style lang="scss">
@import './CreateAndUpdateProvider.scss';
</style>
